<template>
  <div class="step--page">
    <p-table
      ref="table"
      row-key="id"
      :sourceData="getDataApi"
      :columns="columns"
      :pagination="false"
    >
      <template slot="action" slot-scope="text">
        <a-button type="link" @click="handleGetSignUrl">电子签章</a-button>
      </template>
    </p-table>
    <div class="table-btn">
      <a-button type="primary" @click="handleGetSignUrl" >电子签章</a-button>
    </div>
  </div>
</template>

<script>

import { getSignUrl } from '@/api/insurance'

export default {
  name: 'StepPage',
  data () {
    return {
      columns: [
        {
          title: '保险单号',
          dataIndex: 'orderNo',
          key: 'orderNo',
          download: true
        },
        {
          title: '文件名称',
          dataIndex: 'fileName',
          key: 'fileName',
          ellipsis: true,
          download: true
        },
        {
          title: '保险购买状态',
          dataIndex: 'insuranceStatus',
          key: 'insuranceStatus',
          ellipsis: true,
          download: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 140,
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  methods: {
    getDataApi () {
      return Promise.resolve({
        data: [
          {
            id: 1,
            orderNo: this.$route.query.orderNo,
            fileName: '雇主责任险',
            insuranceStatus: '待电子签章'
          }
        ]
      })
    },
    handleGetSignUrl () {
      const orderNo = this.$route.query.orderNo
      this.$spin.show()
      getSignUrl(orderNo).then(res => {
        window.open(res.data)
        this.$confirm({
          title: '电子用印确认',
          content: '我已成功签订电子用印',
          onOk: () => {
            this.$eventBus.$emit('updateStatus')
          }
        })
      }).finally(() => {
        this.$spin.hide()
      })
    }
  }
}
</script>

<style lang="less">
.step--page {
  height: calc(100% - 300px);
  padding-top: 20px;
  .table-btn {
    margin-top: 100px;
    text-align: center;
  }
}
</style>
